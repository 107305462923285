<template>
    <!--ЗӨВӨЛГӨӨ, МЭДЭЭЛЭЛ-->
    <div class="zuvulguu_medeelel_list">
        <div class="zuvulguu_medeelel">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="zuvulguu_medeelel_title"><span class="yelow">{{ 'advice' | translate }}</span>
                            {{ 'information' | translate }}
                            <div class="select">
                                <Select v-model="selectId" style="width:200px" aria-placeholder="Salbar"
                                        :placeholder="'select' | translate">
                                    <Option v-for="item in ds_zovolgoo_angilal_en_view" :value="item.id" :key="item.id">
                                        <div v-if="language.short == 'mn'">{{ item.z_angilal_mn }}</div>
                                        <div v-else>{{ item.z_angilal_en }}</div>
                                    </Option>
                                </Select>
                                <Button shape="circle" icon="ios-search" style="margin-left: 10px"
                                        @click="getNewsList"></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="loading" class="loading-wrap">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="zuvulguu_medeelel_slider">
                    <div class="row">
                        <div class="col-md-4" :key="index" v-for="(z_new, index) in z_news">
                            <div id="slide">
                                <router-link :to="'/advice_information_details/' + z_new.id">
                                    <div class="view view-first">
                                        <img :src="IMAGE_URL + z_new.zurag" alt="">
                                    </div>
                                    <div class="repost-text">
                                        <Button type="primary" class="read">{{ z_new.z_angilal_mn }}</Button>
                                        <router-link :to="'/advice_information_details/' + z_new.id">
                                            <h4 v-if="language.short == 'mn'"> {{ z_new.zovolgoo_mn }} </h4>
                                            <h4 v-else> {{ z_new.zovolgoo_en }} </h4>
                                        </router-link>
                                    </div>
                                    <ul class="post-tags clearfix">
                                        <li>{{ z_new.created_at | date }}</li>
                                        <li class="rigth">
                                            <router-link :to="'/advice_information_details/' + z_new.id">
                                                {{ 'detail' | translate }} <i
                                                    class="ivu-icon ivu-icon-md-return-right"></i></router-link>
                                        </li>
                                    </ul>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pagenation">
                    <Page @on-change="changePage"
                          :current-page="current_page"
                          :page-size="9"
                          :total="total"
                          size="small"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
    GET_ZUWULGUU_MEDEE_ANGILAL,
    GET_ZUWULGUU_MEDEE_PAGE_BY_ID,
    IMAGE_URL,
    GET_ZUWULGUU_MEDEE_PAGE
} from "../graphql/queries";

export default {
    name: "advice_information_list",

    data() {
        return {
            loading: true,
            selectId: null,
            IMAGE_URL: IMAGE_URL,
            ds_zovolgoo_angilal_en_view: [],
            z_news: [],
            model1: '',
            total: 0,
            current_page: 1,
            last_page: 1,
        }
    },
    computed: {
        ...mapGetters([
            'languages',
            'language',
            // ...
        ])
    },
    mounted() {
        this.getGet_zuwulguu_news_category();
        this.getNewsList()
    },
    methods: {
        getGet_zuwulguu_news_category() {
            this.$apollo.query({query: GET_ZUWULGUU_MEDEE_ANGILAL}).then(({data}) => {
                this.ds_zovolgoo_angilal_en_view = data.ds_zovolgoo_angilal_en_view;
            })
        },
        getNewsList() {
            this.loading = true;
            if (this.selectId) {
                this.$apollo.query({
                    query: GET_ZUWULGUU_MEDEE_PAGE_BY_ID,
                    variables: {page: this.current_page, size: 9, cat_id: this.selectId.toString()}
                }).then(({data}) => {
                    this.z_news = data.paginate.ds_zov_medeelel_view;
                    this.total = data.paginate.total;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000)
                })
            } else {
                this.$apollo.query({
                    query: GET_ZUWULGUU_MEDEE_PAGE,
                    variables: {page: this.current_page, size: 9}
                }).then(({data}) => {
                    this.z_news = data.paginate.ds_zov_medeelel_view;
                    this.total = data.paginate.total;
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000)
                })
            }
        },
        changePage(page) {
            this.current_page = page;
            this.getNewsList();
        },
    },
}
</script>

<style scoped>

</style>
